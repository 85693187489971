import { Language } from "../LangSelector/types";
import { FooterLinkType } from "./types";
import { TwitterIcon, TelegramIcon, RedditIcon, InstagramIcon, GithubIcon, DiscordIcon, YoutubeIcon } from "../Svg";

export const footerLinks: FooterLinkType[] = [];

export const socials = [
  {
    label: "Twitter",
    icon: TwitterIcon,
    href: "https://twitter.com",
  },
  {
    label: "Telegram",
    icon: TelegramIcon,
    href: "https://telegram.org",
  },
  {
    label: "Reddit",
    icon: RedditIcon,
    href: "https://reddit.com",
  },
  {
    label: "Github",
    icon: GithubIcon,
    href: "https://github.com",
  },
  {
    label: "Discord",
    icon: DiscordIcon,
    href: "https://discord.gg",
  },
];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
