import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | SwapKit',
  defaultTitle: 'SwapKit',
  description: 'Trade, earn, swap tokens with SwapKit',
  twitter: {
    cardType: 'summary_large_image',
    handle: 'SwapKit',
    site: 'SwapKit',
  },
  openGraph: {
    title: '🥞 SwapKit - Your Reliable DEX Platform',
    description: 'Trade, earn, swap tokens with SwapKit',
    images: [{ url: '' }],
  },
}
